import React, { Component } from 'react'
import PageHeader from '../../../component/pageheader/PageHeader'
// import packageModel from '../../../services/models/admin/packageModel';
// import voucherModel from '../../../services/models/admin/voucherModel';
// import SelectAdvance from '../../../component/selectAdvance/SelectAdvance';
// import NotificationManager from "react-notifications/lib/NotificationManager";
// import { Card, Col, Row, Modal, Input, FormGroup, Label, ModalHeader, ModalBody, ModalFooter, Form } from "reactstrap";

import { NotificationContainer } from "react-notifications";
// import * as ReactDOM from 'react-dom'
// import {
//     TabContent, TabPane, Nav,
//     NavItem, NavLink
// } from 'reactstrap';
import classnames from 'classnames';
import DataTableStandar from '../../../component/datatable/DataTableStandar';
import withdrawModel from '../../../services/models/admin/withdrawModel';
import DataTableStandarDescServerSide from '../../../component/datatable/DataTableStandarDescServerSide';

export default class Bethistorybonus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectValue: { value: "-", label: "-" },
            selectOption: [],
            optionType: 'free',
            addLoading: false,
            error_list_voucher: [],
            currentActiveTab: '1',
            modalUpdate: false,
            idEditData: '',
            txhash: '',
            status: '-',
            errList: '-',


            tableNameUse: "tableBonus",
            thVoucherUse: [
                { title: "DateTime", data: "datetime" },
                { title: "Username", data: "username" },
                { title: "Referral", data: "referral" },
                { title: "Type Coin", data: "type_coin" },
                { title: "Amount", data: "amount" },
                { title: "Amount BET", data: "amount_bet" },
                { title: "Percentage", data: "percentage" },
            ],
            urlEndPointUse: "/admin/bonus/bet/direct",



            tableNameUset: "tableBonusDailyCheckin",
            thVoucherUset: [
                { title: "DateTime", data: "datetime" },
                { title: "Username", data: "username" },
                { title: "Amount", data: "amount" },
            ],
            urlEndPointUset: "/admin/daily/checkin",


        }

    }





    render() {
        return (
            <div>
                <NotificationContainer />
                <PageHeader
                    parentTitle={"Setup"}
                    childTitle={"Bet History "}
                    pageTitle={"Bet History "}
                />



                <div className='card'>
                    <div className="card-body">


                        <DataTableStandarDescServerSide
                            tableName={this.state.tableNameUse}
                            th={this.state.thVoucherUse}
                            // columnDefs={this.state.columnDef}
                            url={this.state.urlEndPointUse}
                            refreshTable={this.state.refreshTable}
                            onChange={(e) => this.setState({ refreshTable: !this.state.refreshTable })}
                        />

                    </div>
                </div>

                <div className='card mt-4'>
                    <div className="card-body">
                        <h6>History Daily SHIBA INU</h6>

                        <DataTableStandarDescServerSide
                            tableName={this.state.tableNameUset}
                            th={this.state.thVoucherUset}
                            // columnDefs={this.state.columnDef}
                            url={this.state.urlEndPointUset}
                            refreshTable={this.state.refreshTable}
                            onChange={(e) => this.setState({ refreshTable: !this.state.refreshTable })}
                        />
                    </div>
                </div>

            </div>




        )
    }





}