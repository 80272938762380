import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../App.scss";
import AppRoutes from "../../routes/Web";
import Navbar from "../navbar/navbar";
import NavbarExchange from "../navbar/navbarExchange";
import SidebarExchange from "../sidebar/sidebarExchange";
import NavbarAdmin from "../navbar/navbarAdmin";
import Sidebar from "../sidebar/sidebarbacklup";
import FooterUser from "../footer/footerUser";
import AuthService from "../../services/auth.service.js";
import "react-notifications/lib/notifications.css";
import SidebarAdmin from "../sidebar/sidebarAdmin";
import './image.css'
import './icon.css';
import authServiceExchanger from "../../services/models/exchanger/auth.service.exchanger";

class App extends Component {

  state = {
    adminArea: '',
    exchangerArea: '',
    pakaiFooter: 'true',
  };


  componentDidMount() {


    this.onRouteChanged()


  }

  handleRefresh() {
    AuthService.refresh()
      .then((res) => {
        clearInterval(this.stopWatchID);
        this.refreshToken();
      })
      .catch((err) => {

      });
  }

  handleRefreshExchange() {
    authServiceExchanger.refresh()
      .then((res) => {
        clearInterval(this.stopWatchID);
        this.refreshToken();
      })
      .catch((err) => {

      });
  }


  handleRefreshAdmin() {
    AuthService.refreshAdmin()
      .then((res) => {
        clearInterval(this.stopWatchIDadmin);
        this.refreshToken();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    let navbarComponent = !this.state.isFullPageLayout ? this.state.adminArea ? <NavbarAdmin /> : this.state.exchangerArea ? <NavbarExchange /> : <Navbar /> : "";
    let sidebarComponent = !this.state.isFullPageLayout ? this.state.adminArea ? <SidebarAdmin /> : this.state.exchangerArea ? <SidebarExchange /> : <Sidebar /> : "";
    let footerComponent = this.state.pakaiFooter ? <FooterUser /> : "";

    return (
      <>
        {this.state.adminArea ?
          <div className="container-scroller">
            {sidebarComponent}
            <div
              className="container-fluid page-body-wrapper"
              style={{
                position: "relative",
                display: "flex",
                height: "100vh",
                overflow: "scroll",
              }}
            >
              {navbarComponent}
              <div className="main-panel">
                <div className="content-wrapper ">
                  <AppRoutes />
                </div>
              </div>
            </div>
          </div>

          :

          this.state.exchangerArea ?
            <>
              <div className="container-scroller">
                {sidebarComponent}
                <div
                  className="container-fluid page-body-wrapper"
                  style={{
                    position: "relative",
                    display: "flex",
                    height: "100vh",
                    overflow: "scroll",
                  }}
                >
                  {navbarComponent}
                  <div className="main-panel">
                    <div className="content-wrapper ">
                      <AppRoutes />
                    </div>
                  </div>
                </div>
              </div>
            </>

            :

            <>
              {/* {navbarComponent} */}
              <div className="container page-body-wrapper">
                <div className="main-panel content-wrapper-user">
                  <AppRoutes />
                  {footerComponent}
                </div>
              </div>
            </>


        }


      </>
    );
  }

  componentDidUpdate(prevProps) {
    clearInterval(this.stopWatchID);
    clearInterval(this.stopWatchIDadmin);
    this.refreshToken();
    this.refreshTokenAdmin();
    this.refreshTokenExchanger();
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  // ngerefresh token biar ga expired
  refreshToken() {
    if (localStorage.getItem("user")) {
      this.stopWatchID = setInterval(() => {
        if (!localStorage.getItem("user")) {
          clearInterval(this.stopWatchID);
        } else {
          const expiration = new Date(
            JSON.parse(atob(localStorage.getItem("user").split(".")[1])).exp
          );
          const now = new Date();
          const valid = expiration.getTime() * 1000 - now.getTime();
          //300000 5 menit
          if (valid < 300000) {
            this.handleRefresh();
          }
        }
      }, 1000);
    }
  }

  refreshTokenExchanger() {
    if (localStorage.getItem("exchanger")) {
      this.stopWatchID = setInterval(() => {
        if (!localStorage.getItem("exchanger")) {
          clearInterval(this.stopWatchID);
        } else {
          const expiration = new Date(
            JSON.parse(atob(localStorage.getItem("exchanger").split(".")[1])).exp
          );
          const now = new Date();
          const valid = expiration.getTime() * 1000 - now.getTime();
          //300000 5 menit
          if (valid < 300000) {
            this.handleRefreshExchange();
          }
        }
      }, 1000);
    }
  }

  refreshTokenAdmin() {
    if (localStorage.getItem("admin")) {
      this.stopWatchIDadmin = setInterval(() => {
        if (!localStorage.getItem("admin")) {
          clearInterval(this.stopWatchIDadmin);
        } else {
          const expiration = new Date(
            JSON.parse(atob(localStorage.getItem("admin").split(".")[1])).exp
          );
          const nowadmin = new Date();
          const validadmin = expiration.getTime() * 1000 - nowadmin.getTime();
          //300000 5 menit
          if (validadmin < 300000) {
            this.handleRefreshAdmin();
          }
        }
      }, 1000);
    }
  }

  onRouteChanged() {

    if (window.location.pathname.split('/')[1] === "backoffice-area") {
      this.setState({
        adminArea: true,
        exchangerArea: false,
      }, () => {
        if (window.location.pathname === "/backoffice-area/login") {
          document
            .querySelector(".page-body-wrapper")
            .classList.add("full-page-wrapper");
        }
      })
    } else if (window.location.pathname.split('/')[1] === "exchanger") {
      this.setState({
        adminArea: false,
        exchangerArea: true,
      }, () => {
        if (window.location.pathname === "/exchanger/login") {
          document
            .querySelector(".page-body-wrapper")
            .classList.add("full-page-wrapper");
        }
      })
    } else {
      this.setState({
        adminArea: false,
      })
    }

    const body = document.querySelector("body");
    if (this.props.location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
    } else {
      body.classList.remove("rtl");
    }
    // window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ["/login", "/register", "/terms/conditon", "/backoffice-area/login", "/exchanger/login", "/privacy-policy"];
    const tidakPakaiFooter = [ "/deposit","/withdrawal" ,"/password", "/history/bonus", '/profile/information'];

    this.setState({
      pakaiFooter: true,
    });
    for (let xx = 0; xx < tidakPakaiFooter.length; xx++) {
      if (this.props.location.pathname === tidakPakaiFooter[xx]) {
        console.log(this.props.location.pathname)
        this.setState({
          pakaiFooter: false,
        });

      }

    }


    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {

      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        // body.classList.remove("imagebg");
        body.classList.add("imagebg");

        this.setState({
          isFullPageLayout: true,
          pakaiFooter: false,
        });

        // console.log(" ====== ROUTE CHANGED ======");

        if (!this.state.adminArea || !this.state.exchangerArea) {

          // document
          // .querySelector(".content-wrapper")
          // .classList.add("");
          document
            .querySelector(".page-body-wrapper")
            .classList.add("full-page-wrapper");


          try {
            document
              .querySelector(".container-fluid page-body-wrapper container")
              .classList.remove("container");
          } catch (error) {

          }


        }

        break;
      } else {

        body.classList.add("imagebg");
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");


        if (!this.state.adminArea || !this.state.exchangerArea) {

          document
            .querySelector(".page-body-wrapper")
          // .classList.add("container");


        }


        try {
          document
            .querySelector(".content-wrapper-user")
            .classList.remove("content-auth");


        } catch (error) {

        }

        try {
          document
            .querySelector(".container-fluid page-body-wrapper container")
            .classList.remove("container");
        } catch (rr) {

        }


        this.setState({
          isFullPageLayout: false,
        });

      }
    }




  }
}

export default withRouter(App);
