import authHeaderAdmin from "../../auth-header-admin";


class dashboardModel {

    dashboardReq() {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/dashboard/requier')
    }


}

export default new dashboardModel();
