import authHeaderAdmin from "../../auth-header-admin";

class withdrawModel {
    changeStatus(id, status, txhash) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/withdraw/change', {
            id: id,
            status,
            txhash,
        })
    }


    actionWithdrawTwo(datetime, user, wallet, amount, fee) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/withdraw/action/two', {
            datetime, user, wallet, amount, fee
        })
    }

    changeStatusWithdrawTwo(id, status, txhash) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/withdraw/change/withdrawtwo', {
            id: id,
            status,
            txhash,
        })
    }


    editWithdrawTwo(id, datetime, wallet, amount, amount_fee, txhash) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/withdraw/two/edit', {
            id,
            datetime,
            wallet,
            amount,
            amount_fee,
            txhash,
        })
    }


      deleteWithdrawTwo(id) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/withdraw/two/delete', {
            id
        })
    }

    addWithdraw(user_id, amount){
         return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/withdraw/add', {
            user_id,
            amount
        })
    }



}

export default new withdrawModel();
