import React, { Component } from 'react'
import * as ReactDOM from 'react-dom'
import PageHeaderAdmin from '../../../component/pageheader/PageHeader'
import SelectAdvance from '../../../component/selectAdvance/SelectAdvance';
import { NotificationContainer } from "react-notifications";
import openTradeModel from '../../../services/models/admin/openTradeModel';
import DataTableStandar from '../../../component/datatable/DataTableStandar';
import { Card, Col, Row, Modal, Input, FormGroup, Label, ModalHeader, ModalBody, ModalFooter, Form } from "reactstrap";
import NotificationManager from "react-notifications/lib/NotificationManager";
import DashboardAdmin from '../dashboard/Dashboard';
import generalModel from '../../../services/models/admin/generalModel';
import profileModel from '../../../services/models/admin/profileModel';


export default class SetupBet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            position: 'admin',
            tableName: "levelingtable",


            vipWin: '',
            vipLose: '',
            dailyWin: '',
            dailyLose: '',
            maxProfitUsdt: '',
            maxProfitBnb: '',
            maxProfitFoxy: '',
            kondisi1: '',
            kondisi2: '',
            kondisi3: '',
            kondisi4: '',
            kondisi5: '',
            kondisi6: '',
            kondisi7: '',
            kondisi8: '',
            kondisi9: '',
            kondisi10: '',
            kondisi11: '',
            kondisi12: '',
            kondisi13: '',
            kondisi14: '',
            kondisi15: '',
            kondisi16: '',
            kondisi17: '',
            kondisi18: '',
            kondisi19: '',
            kondisi20: '',
            kondisi21: '',
            kondisi22: '',
            kondisi23: '',
            kondisi24: '',
            kondisi25: '',

            win_limit_usdt: '',
            win_limit_bnb: '',
            win_limit_shiba_inu: '',


            optionReferral: [],
            optionSelectReferral: "",
            condition: 'win',

            errList: [],
            loadingUpdate: false,

            modalAdd: false,
            level: '',
            percentase: '',
            errLeveling: [],
            loadingLevel: false,

            modalDelete: false,
            idDelete: '',
            loadingDelete: false,


            modalEdit: false,
            idEdit: '',
            editLevel: '',
            editPercentase: '',
            errListEdit: [],
            loadingEdit: false,


        }
    }

    componentDidMount() {




        profileModel.me().then(res => {
            this.setState({
                position: res.data.position
            })
        })

        generalModel.variableTrade().then(res => {
            this.setState({
                vipWin: res.data.vipWin,
                vipLose: res.data.vipLose,
                dailyWin: res.data.dailyWin,
                dailyLose: res.data.dailyLose,
                maxProfitUsdt: res.data.maxProfitUsdt,
                maxProfitBnb: res.data.maxProfitBnb,
                maxProfitFoxy: res.data.maxProfitFoxy,
                kondisi1: res.data.kondisi1,
                kondisi2: res.data.kondisi2,
                kondisi3: res.data.kondisi3,
                kondisi4: res.data.kondisi4,
                kondisi5: res.data.kondisi5,
                kondisi5: res.data.kondisi5,
                kondisi6: res.data.kondisi6,
                kondisi7: res.data.kondisi7,
                kondisi8: res.data.kondisi8,
                kondisi9: res.data.kondisi9,
                kondisi10: res.data.kondisi10,
                kondisi11: res.data.kondisi11,
                kondisi12: res.data.kondisi12,
                kondisi13: res.data.kondisi13,
                kondisi14: res.data.kondisi14,
                kondisi15: res.data.kondisi15,
                kondisi16: res.data.kondisi16,
                kondisi17: res.data.kondisi17,
                kondisi18: res.data.kondisi18,
                kondisi19: res.data.kondisi19,
                kondisi20: res.data.kondisi20,
                kondisi21: res.data.kondisi21,
                kondisi22: res.data.kondisi22,
                kondisi23: res.data.kondisi23,
                kondisi24: res.data.kondisi24,
                kondisi25: res.data.kondisi25,

                win_limit_usdt: res.data.win_limit_usdt,
                win_limit_bnb: res.data.win_limit_bnb,
                win_limit_shiba_inu: res.data.win_limit_shiba_inu,

            })
        })
    }

    submitGeneral = (e) => {
        e.preventDefault();
        this.setState({
            loadingUpdate: true
        })
        generalModel.variableTradeUpdate(
            this.state.vipWin,
            this.state.vipLose,
            this.state.dailyWin,
            this.state.dailyLose,
            this.state.maxProfitUsdt,
            this.state.maxProfitBnb,
            this.state.maxProfitFoxy,
            this.state.kondisi1,
            this.state.kondisi2,
            this.state.kondisi3,
            this.state.kondisi4,
            this.state.kondisi5,
            this.state.kondisi6,
            this.state.kondisi7,
            this.state.kondisi8,
            this.state.kondisi9,
            this.state.kondisi10,
            this.state.kondisi11,
            this.state.kondisi12,
            this.state.kondisi13,
            this.state.kondisi14,
            this.state.kondisi15,
            this.state.kondisi16,
            this.state.kondisi17,
            this.state.kondisi18,
            this.state.kondisi19,
            this.state.kondisi20,
            this.state.kondisi21,
            this.state.kondisi22,
            this.state.kondisi23,
            this.state.kondisi24,
            this.state.kondisi25,

            this.state.win_limit_usdt,
            this.state.win_limit_bnb,
            this.state.win_limit_shiba_inu,


        ).then(res => {
            NotificationManager.success(res.data.message, "Success");
            this.setState({
                loadingUpdate: false
            })
        }).catch(eer => {
            this.setState({
                loadingUpdate: false,
                errList: eer.response.data.errors,
            })
        })

    }

    addLevel = (e) => {
        e.preventDefault();
        this.setState({
            loadingLevel: true
        })


        console.log(this.state.condition)

        generalModel.vipUserPost(this.state.optionSelectReferral.value, this.state.condition).then(res => {
            NotificationManager.success(res.data.message, "Success");
            this.setState({
                loadingLevel: false,
                modalAdd: false,
                optionSelectReferral: {
                    value: "-",
                    label: "-",
                },
                condition: 'win',
                refreshTable: true,

            }, () => {
                generalModel.vipUser().then((res) => {
                    this.setState(
                        {
                            optionReferral: res.data.option,
                            optionSelectReferral: {
                                value: "-",
                                label: "-",
                            },
                        }
                    );
                });
            })
        }).catch(erere => {
            this.setState({
                loadingLevel: false,
                errLeveling: erere.response.data.errors,
            })
        })


    }




    changeRefresh = (e) => {
        this.setState({
            refreshTable: e
        })
    }

    handleDeleteModal = (data) => {
        this.setState({
            modalDelete: true,
            idDelete: data.id
        })
    }




    deleteLevelingBonus = (e) => {
        e.preventDefault();
        this.setState({
            loadingDelete: true
        })

        generalModel.vipDelete(this.state.idDelete).then(res => {
            NotificationManager.success(res.data.message, "Success");
            this.setState({
                loadingDelete: false,
                refreshTable: true,
                modalDelete: false
            })
        })


    }


    handleEditData = (data) => {

        this.setState({
            modalEdit: true,
            idEdit: data.id,
            editLevel: data.level,
            editPercentase: data.percentase,
        })

    }



    EditLeveling = (e) => {
        e.preventDefault();
        this.setState({
            loadingEdit: true
        })
        generalModel.editLevel(this.state.idEdit, this.state.editLevel, this.state.editPercentase).then(res => {
            NotificationManager.success(res.data.message, "Success");
            this.setState({
                loadingEdit: false,
                refreshTable: true,
                modalEdit: false
            })
        }).catch(errrr => {
            this.setState({
                loadingEdit: false,
                errListEdit: errrr.response.data.errors,
            })
        })
    }


    changeSelectReferral = (e) => {
        this.setState({
            optionSelectReferral: e,
            referralEditUser: e.value,
        });
    };



    render() {
        return (
            <div>
                <NotificationContainer />
                <PageHeaderAdmin
                    parentTitle={"Setup"}
                    childTitle={"Setup Variable Trade"}
                    pageTitle={"Setup Variable Trade"}
                />
                <div className='card'>
                    <div className='card-body'>
                        <div className='pb-4'>Trade Variable : </div>
                        <form onSubmit={this.submitGeneral}>

                       
                            <hr></hr>
                            <div className='pb-4'>Conditional Trade winchance {'>'} 0 and  {'<='} 20 </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                    <label>Total Bet Terakhir :</label>
                                        <input required className='form-control' value={this.state.kondisi1}
                                            onChange={(e) => {
                                                this.setState({
                                                    kondisi1: e.target.value
                                                })
                                            }
                                            }></input>
                                        <small>{this.state.errList.kondisi1}</small>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Max Bet Win :</label>
                                        <input required className='form-control' value={this.state.kondisi2}
                                            onChange={(e) => {
                                                this.setState({
                                                    kondisi2: e.target.value
                                                })
                                            }
                                            }></input>
                                        <small>{this.state.errList.kondisi2}</small>
                                    </div>
                                </div>

                                

                            </div>


                            <div className='pb-4'>Conditional Trade winchance {'>'} 20 and  {'<='} 40 </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Total Bet Terakhir :</label>
                                        <input required className='form-control' value={this.state.kondisi3}
                                            onChange={(e) => {
                                                this.setState({
                                                    kondisi3: e.target.value
                                                })
                                            }
                                            }></input>
                                        <small>{this.state.errList.kondisi3}</small>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Max Bet Win: </label>
                                        <input required className='form-control' value={this.state.kondisi4}
                                            onChange={(e) => {
                                                this.setState({
                                                    kondisi4: e.target.value
                                                })
                                            }
                                            }></input>
                                        <small>{this.state.errList.kondisi4}</small>
                                    </div>
                                </div>

                            </div>


                            <div className='pb-4'>Conditional Trade winchance {'>'} 40 and  {'<='} 60 </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Total Bet Terakhir: </label>
                                        <input required className='form-control' value={this.state.kondisi5}
                                            onChange={(e) => {
                                                this.setState({
                                                    kondisi5: e.target.value
                                                })
                                            }
                                            }></input>
                                        <small>{this.state.errList.kondisi5}</small>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Max Bet Win: </label>
                                        <input required className='form-control' value={this.state.kondisi6}
                                            onChange={(e) => {
                                                this.setState({
                                                    kondisi6: e.target.value
                                                })
                                            }
                                            }></input>
                                        <small>{this.state.errList.kondisi6}</small>
                                    </div>
                                </div>

                            </div>

                            <div className='pb-4'>Conditional Trade winchance {'>'} 60 and  {'<='} 80 </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Total Bet Terakhir: </label>
                                        <input required className='form-control' value={this.state.kondisi7}
                                            onChange={(e) => {
                                                this.setState({
                                                    kondisi7: e.target.value
                                                })
                                            }
                                            }></input>
                                        <small>{this.state.errList.kondisi7}</small>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Max Bet Win: </label>
                                        <input required className='form-control' value={this.state.kondisi8}
                                            onChange={(e) => {
                                                this.setState({
                                                    kondisi8: e.target.value
                                                })
                                            }
                                            }></input>
                                        <small>{this.state.errList.kondisi8}</small>
                                    </div>
                                </div>

                            </div>

                            <div className='pb-4'>Conditional Trade winchance {'>'} 80 and  {'<='} 100 </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Total Bet Terakhir : </label>
                                        <input required className='form-control' value={this.state.kondisi9}
                                            onChange={(e) => {
                                                this.setState({
                                                    kondisi9: e.target.value
                                                })
                                            }
                                            }></input>
                                        <small>{this.state.errList.kondisi9}</small>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Max Bet Win: </label>
                                        <input required className='form-control' value={this.state.kondisi10}
                                            onChange={(e) => {
                                                this.setState({
                                                    kondisi10: e.target.value
                                                })
                                            }
                                            }></input>
                                        <small>{this.state.errList.kondisi10}</small>
                                    </div>
                                </div>

                            </div>




                            {/* {this.state.position === 'admin' ? <>
                                <p></p>
                            </> : <>
                                <button type='submit' disabled={this.state.loadingUpdate} className="btn btn-primary float-right"

                                >Save Changes  {this.state.loadingUpdate && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}</button>
                            </>} */}
                            <button type='submit' disabled={this.state.loadingUpdate} className="btn btn-primary float-right"

                            >Save Changes  {this.state.loadingUpdate && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}</button>
                        </form>



                    </div>
                </div>




                <Modal
                    isOpen={this.state.modalAdd}
                    toggle={(e) => {
                        this.setState({
                            modalAdd: !this.state.modalAdd
                        })
                    }}
                    centered={true}
                >
                    <ModalHeader toggle={() => this.setState({ modalAdd: false })}>Add Vip Win/Lose</ModalHeader>
                    <ModalBody>
                        <form>

                            <div className="form-group">
                                <label htmlFor="selectReferral">Username :</label>
                                <SelectAdvance
                                    onChange={this.changeSelectReferral.bind(this)}
                                    select={this.state.optionReferral}
                                    value={this.state.optionSelectReferral}
                                ></SelectAdvance>

                                <small className="text-danger">
                                    {this.state.errLeveling.user}
                                </small>
                            </div>

                            <div className='form-group'>
                                <label>Condition : </label>
                                <select className='form-control'
                                    onChange={(e) => this.setState({
                                        condition: e.target.value
                                    })} value={this.state.condition}>
                                    <option value="lose">Lose</option>
                                    <option value="win">Win</option>
                                </select>

                                <small className="text-danger">
                                    {this.state.errLeveling.condition}
                                </small>
                            </div>


                        </form>


                    </ModalBody>
                    <ModalFooter>

                        <button className="btn btn-primary btn-block"
                            onClick={(e) => { this.addLevel(e) }}
                            disabled={this.state.loadingLevel} >
                            Add VIP WIN/LOSE {this.state.loadingLevel && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}</button>
                    </ModalFooter>
                </Modal>



                <Modal
                    isOpen={this.state.modalEdit}
                    toggle={(e) => {
                        this.setState({
                            modalEdit: !this.state.modalEdit
                        })
                    }}
                    centered={true}
                >
                    <ModalHeader toggle={() => this.setState({ modalEdit: false })}>Edit Leveling Bonus</ModalHeader>
                    <ModalBody>
                        <form>
                            <div className='form-group'>
                                <label> Level :</label>
                                <input required className='form-control' value={this.state.editLevel}
                                    onChange={(e) => {
                                        this.setState({
                                            editLevel: e.target.value
                                        })
                                    }}>
                                </input>
                                <small className='text-danger'>{this.state.errListEdit.level}</small>
                            </div>

                            <div className='form-group'>
                                <label>Percentase :</label>
                                <input required className='form-control' value={this.state.editPercentase}
                                    onChange={(e) => {
                                        this.setState({
                                            editPercentase: e.target.value
                                        })
                                    }}>
                                </input>
                                <small className='text-danger'>{this.state.errListEdit.percentase}</small>
                            </div>
                        </form>


                    </ModalBody>
                    <ModalFooter>

                        <button className="btn btn-primary btn-block"
                            onClick={(e) => { this.EditLeveling(e) }}
                            disabled={this.state.loadingEdit} >
                            Edit Leveling Bonuses {this.state.loadingEdit && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}</button>
                    </ModalFooter>
                </Modal>




                <Modal
                    isOpen={this.state.modalDelete}
                    toggle={(e) => {
                        this.setState({
                            modalDelete: !this.state.modalDelete
                        })
                    }}
                    centered={true}
                >
                    <ModalHeader toggle={() => this.setState({ modalDelete: false })}>Delete Leveling Bonus</ModalHeader>
                    <ModalBody>
                        <div className='text-center align-middle'>
                            <span>Are you sure want delete this Vip User?</span>
                        </div>

                    </ModalBody>
                    <ModalFooter>

                        <button className="btn btn-primary btn-block"
                            onClick={(e) => { this.deleteLevelingBonus(e) }}
                            disabled={this.state.loadingDelete} >
                            Delete Vip User {this.state.loadingDelete && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}</button>
                    </ModalFooter>
                </Modal>


            </div >
        )
    }
}