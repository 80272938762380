import React, { Component } from "react";
import Select from "react-select";

class SelectAdvance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.select,
    };
  }

  componentDidUpdate(prevProps)
  {
    if(prevProps.select !== this.props.select){
      this.setState({
        data: this.props.select,
      })
    }
  }


  setSelectedOption = (e) => {
     this.props.onChange(e);
  }

  render() {
 
            
    return (
      <div>
        <Select 
            defaultValue={this.props.value}
            onChange={this.setSelectedOption.bind(this)}
            options={this.state.data} 
            value={this.props.value}/>
      </div>
    );
  }
}

export default SelectAdvance;
