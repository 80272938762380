import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import './footer.css';

import { MenuHome, MenuShop, MenuBalance, MenuDextrade, MenuAccount, BonusReferral } from '../../component/icon/Icon';


class FooterUser extends Component {
  render() {
    return (
      <>







        {/* <div className='container footer-mainn'>

          <div className='d-flex justify-content-between '>

            <NavLink  to="/dashboard" activeClassName="active-user">
              <div className="sub-menu text-center align-middle">
                <span className="menu-icon icon-md  text-white">
                  <i className="mdi mdi-chart-bar"></i>
                </span>
                <span className="menu-title text-white">Home</span>
              </div>
            </NavLink>



            <NavLink activeClassName="active-user" to="/setup">
              <div className="sub-menu text-center align-middle">
                <span className="menu-icon icon-md pr-3 text-white">
                  <i className="mdi mdi-clipboard-text"></i>
                </span>
                <span className="menu-title text-white">Bot Setup</span>
              </div>
            </NavLink>



            <NavLink activeClassName="active-user" to="/package">
              <div className="sub-menu text-center align-middle">
                <span className="menu-icon icon-md pr-3 text-white">
                  <i className="mdi mdi-cart"></i>
                </span>
                <span className="menu-title text-white">Bot Type</span>
              </div>
            </NavLink>


            <NavLink activeClassName="active-user" to="/transaction/dex-trade">
              <div className="sub-menu text-center align-middle">
                <span className="menu-icon icon-md pr-3 text-white">
                  <i className="mdi mdi-chart-line
"></i>
                </span>
                <span className="menu-title text-white">DEX Trade</span>
              </div>
            </NavLink>


            <NavLink activeClassName="active-user" to="/transaction/history">
              <div className="sub-menu text-center align-middle">
                <span className="menu-icon icon-md pr-3 text-white">
                  <i className="mdi mdi-file-multiple
"></i>
                </span>
                <span className="menu-title text-white">Transaction   </span>
              </div>
            </NavLink>
          </div>


        </div> */}

        <div className="container cont">


          {/* <div className='d-flex ' style={{ zIndex: -12 }} >
            <div className=' container-conttleft align-items-start' >


            </div>
            <div className=' container-conttright ml-auto ' >


            </div>

          </div> */}


          <div className=' menu-main-custom' >
            <div className='d-flex justify-content-between' style={{ paddingLeft: "0.6rem", paddingRight: "0.6rem" }}>
              <NavLink to="/dashboard" activeClassName="active-user ">
                <div className="sub-menu text-center align-middle">
                  <span className="menu-icon icon-md  text-white">
                    <span className="svg-icon svg-icon-white ">
                      <MenuHome />
                    </span>
                  </span>
                  <span className="menu-title " style={{ marginTop: "1px", color:"#ff6421" }} >Home
                  </span>
                </div>
              </NavLink>



              <NavLink activeClassName="active-user" to="/referral">
                <div className="sub-menu text-center align-middle">
                  <span className="menu-icon icon-md  text-white">
                    <span className="svg-icon svg-icon-white ">
                      <BonusReferral />
                    </span>
                  </span>
                  <span className="menu-title " style={{ color:"#ff6421" }}>Referral</span>
                </div>
              </NavLink>

              <NavLink activeClassName="active-user" to="/transaction/bet-trade">
                <div className="sub-menu text-center align-middle">
                  <span className="menu-icon icon-md text-white">
                    <span className="svg-icon svg-icon-white ">
                      <MenuDextrade />
                    </span>
                  </span>
                  <span className="menu-title" style={{ color:"#ff6421" }}>BET Report</span>
                </div>
              </NavLink>


              <NavLink activeClassName="active-user" to="/bonus">
                <div className="sub-menu text-center align-middle">
                  <span className="menu-icon icon-md text-white">
                    <span className="svg-icon svg-icon-white ">
                      <MenuBalance />
                    </span>
                  </span>
                  <span className="menu-title" style={{ color:"#ff6421" }}>Bonuses</span>
                </div>
              </NavLink>


        


              <NavLink activeClassName="active-user" to="/profile">
                <div className="sub-menu text-center align-middle">
                  <span className="menu-icon icon-md text-white">
                    <span className="svg-icon svg-icon-white ">
                      <MenuAccount />
                    </span>
                  </span>
                  <span className="menu-title " style={{ color:"#ff6421" }}>Profile</span>
                </div>
              </NavLink>

            </div>


          </div>
        </div>




        {/* <footer className="footer">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © <a href="https://memberlogin-nubi.backoffice-id.com" target="_blank" rel="noopener noreferrer">KYLE </a>2023</span>
          </div>
        </div>
      </footer> */}
      </>

    );
  }
}

export default FooterUser;