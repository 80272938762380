import React, { Component } from 'react'
import * as ReactDOM from 'react-dom'
import PageHeaderAdmin from '../../../component/pageheader/PageHeader'
import SelectAdvance from '../../../component/selectAdvance/SelectAdvance';
import { NotificationContainer } from "react-notifications";
import openTradeModel from '../../../services/models/admin/openTradeModel';
import DataTableStandar from '../../../component/datatable/DataTableStandar';
import { Card, Col, Row, Modal, Input, FormGroup, Label, ModalHeader, ModalBody, ModalFooter, Form } from "reactstrap";
import NotificationManager from "react-notifications/lib/NotificationManager";
import DashboardAdmin from '../dashboard/Dashboard';
import generalModel from '../../../services/models/admin/generalModel';
import profileModel from '../../../services/models/admin/profileModel';


export default class MonitorNonVip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            position: 'admin',
            tableName: "levelingtable",
            errListEdit: [],
            loadingEdit: false,
            target: 'bnb',

            maxToday: '0',
            maxProfit: '0',
            idEdit: '0',
            dailyWin:"0",

            modalEdit: false,
            errListEdit: [],


            th: [
                { title: "Username", data: "username" },
                { title: "Total Deposit", data: "deposit" },
                { title: "Current Balance", data: "current_balance" },
                { title: "MAX TODAY P/L", data: "max_today" },
                { title: "RUNNING TODAY P/L", data: "running_today" },
                { title: "TODAY WIN", data: "today_win" },
                { title: "TODAY LOSE", data: "today_lose" },
                { title: "MAX PROFIT /BET", data: "max_profit" },
                { title: "MAX DAILY WIN", data: "daily_win" },
                {
                    title: "Action",
                    data: "id"
                }
            ],

            columnDef: [
                {
                    targets: 9,
                    createdCell: (td, cellData, rowData, row, col) =>
                        ReactDOM.render(
                            [
                                <span style={{ cursor: 'pointer' }} onClick={() => this.handleEdit(rowData)} className="mdi mdi-pencil p-2 pt-2 text-success" id="' + data + '" data="' + data + '"></span>,
                            ],
                            td),
                }
            ],


            urlEndPoint: "/admin/bet/monitoring/non/bnb",

        }
    }

    componentDidMount() {



        profileModel.me().then(res => {
            this.setState({
                position: res.data.position
            })
        })

    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.target !== this.state.target) {
            if (this.state.target == "bnb") {
                this.setState({
                    urlEndPoint: "/admin/bet/monitoring/non/bnb",
                    refreshTable: true
                })
            } else if (this.state.target == "usdt") {
                this.setState({
                    urlEndPoint: "/admin/bet/monitoring/non/usdt",
                    refreshTable: true
                })
            } else {
                this.setState({
                    urlEndPoint: "/admin/bet/monitoring/non/shiba",
                    refreshTable: true
                })
            }
        }
    }


    changeRefresh = (e) => {
        console.log("refresh")
        this.setState({
            refreshTable: e
        })
    }

    changeCurrent = (e) => {
        this.setState({
            target: e.target.value
        })
    }



    handleEdit = (e) => {
        this.setState({
            maxToday: e.max_today,
            maxProfit: e.max_profit,
            dailyWin: e.daily_win,
            idEdit: e.id,
            modalEdit: true,
        })
    }


    starStopHandle = (e) => {
        generalModel.startStopVariable(e.id, this.state.target).then(res => {
            NotificationManager.success(res.data.message, "Success");
            this.setState({
                loadingEdit: false,
                refreshTable: true,
            })
        }).catch(errrr => {
            this.setState({
                loadingEdit: false,
            })
        })
    } 



    EditVariable = (e) => {
        e.preventDefault();
        this.setState({
            loadingEdit: true
        })
        generalModel.editVariableNon(this.state.idEdit, this.state.maxToday, this.state.maxProfit, this.state.dailyWin, this.state.target).then(res => {
            NotificationManager.success(res.data.message, "Success");
            this.setState({
                loadingEdit: false,
                refreshTable: true,
                modalEdit: false
            })
        }).catch(errrr => {
            this.setState({
                loadingEdit: false,
                errListEdit: errrr.response.data.errors,
            })
        })
    }



    render() {
        return (
            <div>
                <NotificationContainer />
                <PageHeaderAdmin
                    parentTitle={"Setup"}
                    childTitle={"Setup Variable Trade"}
                    pageTitle={"Setup Variable Trade"}
                />

                <div className='card mt-4'>
                    <div className='card-body'>
                        <div className='pb-4'>Monitoring NON VIP : </div>

                        {/* {this.state.position === 'admin' ? <>
                            <p></p>
                        </> : <>
                            <button className='btn btn-primary mb-3'
                                onClick={(e) => {
                                    this.setState({
                                        modalAdd: true
                                    })
                                }}><i className='mdi mdi-plus'></i></button>
                        </>} */}

                        <div className='row pb-4'>
                            <div className='col-md-4'>
                                <label>Select CRYPTO :</label>
                                <select className='form-control'
                                    onChange={(e) => this.changeCurrent(e)}
                                    value={this.target}
                                >
                                    <option value="bnb">BNB</option>
                                    <option value="usdt">USDT</option>
                                    <option value="shiba">SHIBA INU</option>
                                </select>

                            </div>
                        </div>


                        <DataTableStandar
                            tableName={this.state.tableName}
                            th={this.state.th}
                            columnDefs={this.state.columnDef}
                            url={this.state.urlEndPoint}
                            refreshTable={this.state.refreshTable}
                            onChange={this.changeRefresh.bind(this)}
                        />


                    </div>
                </div>



                <Modal
                    isOpen={this.state.modalEdit}
                    toggle={(e) => {
                        this.setState({
                            modalEdit: !this.state.modalEdit
                        })
                    }}
                    centered={true}
                >
                    <ModalHeader toggle={() => this.setState({ modalEdit: false })}>Edit Variable Trade </ModalHeader>
                    <ModalBody>
                        <form>
                            <div className='form-group'>
                                <label> MAX TODAY P/L:</label>
                                <input required className='form-control' value={this.state.maxToday}
                                    onChange={(e) => {
                                        this.setState({
                                            maxToday: e.target.value
                                        })
                                    }}>
                                </input>
                                <small className='text-danger'>{this.state.errListEdit.maxToday}</small>
                            </div>

                            <div className='form-group'>
                                <label>MAX PROFIT /BET :</label>
                                <input required className='form-control' value={this.state.maxProfit}
                                    onChange={(e) => {
                                        this.setState({
                                            maxProfit: e.target.value
                                        })
                                    }}>
                                </input>
                                <small className='text-danger'>{this.state.errListEdit.maxProfit}</small>
                            </div>


                            <div className='form-group'>
                                <label>MAX DAILY WIN :</label>
                                <input required className='form-control' value={this.state.dailyWin}
                                    onChange={(e) => {
                                        this.setState({
                                            dailyWin: e.target.value
                                        })
                                    }}>
                                </input>
                                <small className='text-danger'>{this.state.errListEdit.dailyWin}</small>
                            </div>

                        </form>


                    </ModalBody>
                    <ModalFooter>

                        <button className="btn btn-primary btn-block"
                            onClick={(e) => { this.EditVariable(e) }}
                            disabled={this.state.loadingEdit} >
                            Edit MAX P/L & PROFIT PER BET {this.state.loadingEdit && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}</button>
                    </ModalFooter>
                </Modal>


            </div >
        )
    }
}