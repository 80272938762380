import authHeaderAdmin from "../../auth-header-admin";

class openTradeModel {
    userList(packageid) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/opentrade/list/user', {
            packageid
        })
    }

    tokenList() {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/opentrade/list/token')
    }


    detailTradeUser(id) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/opentrade/trade/user', {
            id
        })
    }


    openTradeReq(user, token, amount) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/opentrade/trade/req', {
            user,
            token,
            amount,
        })
    }

    openTradeAction(user, token, amount) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/opentrade/trade/action', {
            user,
            token,
            amount,
        })
    }


    cancleOpenTrade(id) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/opentrade/trade/cancle', {
            id,
        })
    }

    closeOpenTrade(id) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/opentrade/trade/close', {
            id,
        })
    }


    botpackageList() {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/opentrade/list/botpackage')
    }


    sellAll(tokenId) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/opentrade/trade/close/all', {
            tokenId
        })

    }


    openTradeGroup(group, token) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/opentrade/trade/group', {
            group,
            token
        })
    }

    openTradeGroupReq(group, token) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/opentrade/trade/group/req', {
            group,
            token
        })
    }


}

export default new openTradeModel();
