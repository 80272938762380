import React, { Component } from "react";
import { NotificationContainer } from "react-notifications";

class PageHeader extends Component {


  constructor(props) {
    super(props);
    this.state = {
      wallet: "",
    };
  }

  render() {
    return (
      <div className="page-header" >
        <NotificationContainer />
        <div>
          <h3 className="page-title">{this.props.pageTitle}</h3>
          <small>Simple, Profitable and Unique Trading "System"</small>
        </div>

        <button className="btn btn-main btn-custom" style={{ textOverflow: "ellipsis", cursor: 'unset'}}>{this.props.valueButton}</button>
      </div >

    );
  }
}

export default PageHeader;
