import React, { Component } from 'react'
import PageHeader from '../../../component/pageheader/PageHeader'
import dashboardModel from '../../../services/models/admin/dashboardModel';
import profileModel from '../../../services/models/admin/profileModel';

export default class DashboardAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {

      totalUser: '',
      totalUserFake: '',
     
      totalDepositUserUSDT: '',
      totalDepositUserBNB: '',
      totalDepositUserFOXY: '',
      totalBalanceUserUSDT: '',
      totalBalanceUserBNB: '',
      totalBalanceUserFOXY: '',
      totalActiveBot: '',
      withdraw: '',
      totalBonusUser: '',
      transactionPl: '',
      totalpembelianPackage: '',

      totalDtwo: '',
      transactionPlTwo: '',
      withdrawTwo: '',
      position: 'admin',
      withdrawDeposit: '',
      withdrawDepositBNB: '',
      withdrawDepositFOXY: '',
    }
  }

  componentDidMount() {

    profileModel.me().then(res => {
      this.setState({
        position: res.data.position
      })
    })

    dashboardModel.dashboardReq().then(res => {
      this.setState({
        totalUser: res.data.totalUser,
        totalUserFake: res.data.totalUserFake,

        totalBalanceUserUSDT: res.data.totalBalanceUserUSDT,
        totalBalanceUserBNB: res.data.totalBalanceUserBNB,
        totalBalanceUserFOXY: res.data.totalBalanceUserFOXY,
        totalDepositUserUSDT: res.data.totalDepositUserUSDT,
        totalDepositUserBNB: res.data.totalDepositUserBNB,
        totalDepositUserFOXY: res.data.totalDepositUserFOXY,
        totalActiveBot: res.data.totalActiveBot,
        withdraw: res.data.withdraw,
        totalBonusUser: res.data.totalBonusUser,
        transactionPl: res.data.transactionPl,
        totalDtwo: res.data.totalDepositUserTwo,
        withdrawTwo: res.data.withdrawTwo,

        transactionPlTwo: res.data.transactionPlTwo,
        totalpembelianPackage: res.data.totalpembelianPackage,
        withdrawDeposit: res.data.withdrawDeposit,
        withdrawDepositBNB: res.data.withdrawDepositBNB,
        withdrawDepositUSDT: res.data.withdrawDepositUSDT,
        withdrawDepositFOXY: res.data.withdrawDepositFOXY,
        withdrawBonus: res.data.withdrawBonus

      })
    })
  }

  render() {


    return (
      <div>
        <PageHeader
          parentTitle={"Dashboard"}
          childTitle={"Dashboard"}
          pageTitle={"Dashboard"}
        />

        {this.state.position !== "admin" ? <>
          <div className="row">
            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(236, 241, 82, 0.34)" }}><i className="mdi mdi-account-multiple-outline icon-md text-primary" ></i></div>

                    <div className="col-9">
                      <small>Total User:  <br></br></small>
                      <span>{this.state.totalUser}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(236, 241, 82, 0.34)" }}>
                      <i className="mdi mdi-account-multiple-minus icon-md text-primary" ></i></div>

                    <div className="col-9">
                      <small>Total Fake User:  <br></br></small>
                      <span>{this.state.totalUserFake}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center"
                      style={{ background: "rgba(105, 59, 134, 0.20)" }}>
                      <i className="mdi mdi-chart-bubble icon-md text-main"></i></div>

                    <div className="col-9">
                      <small>Total Active Bot : <br></br></small>
                      <span>{this.state.totalActiveBot} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center"
                      style={{ background: "rgba(105, 59, 134, 0.20)" }}>
                      <i className="mdi mdi-cart icon-md text-main"></i></div>

                    <div className="col-9">
                      <small>Sales Bot : <br></br></small>
                      <span>{this.state.totalpembelianPackage} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>







            <div className="col-md-4 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(82, 242, 107, 0.34)" }}><i className="mdi mdi-wallet icon-md text-success"></i></div>

                    <div className="col-9">
                      <small>Total Balance USDT User : <br></br></small>
                      <span>{this.state.totalBalanceUserUSDT} USDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-4 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(82, 242, 107, 0.34)" }}><i className="mdi mdi-wallet icon-md text-success"></i></div>

                    <div className="col-9">
                      <small>Total Balance BNB User : <br></br></small>
                      <span>{this.state.totalBalanceUserBNB} BNB</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(82, 242, 107, 0.34)" }}><i className="mdi mdi-wallet icon-md text-success"></i></div>

                    <div className="col-9">
                      <small>Total Balance SHIBA INU User : <br></br></small>
                      <span>{this.state.totalBalanceUserFOXY} SHIBA INU</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-4 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(179, 82, 240, 0.34)" }}><i className="mdi mdi-cash-usd icon-md text-info"></i></div>

                    <div className="col-9">
                      <small>Total Deposit USDT User : <br></br></small>
                      <span>{this.state.totalDepositUserUSDT} USDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(179, 82, 240, 0.34)" }}><i className="mdi mdi-cash-usd icon-md text-info"></i></div>

                    <div className="col-9">
                      <small>Total Deposit BNB User : <br></br></small>
                      <span>{this.state.totalDepositUserBNB} BNB</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(179, 82, 240, 0.34)" }}><i className="mdi mdi-cash-usd icon-md text-info"></i></div>

                    <div className="col-9">
                      <small>Total Deposit SHIBA INU User : <br></br></small>
                      <span>{this.state.totalDepositUserBNB} SHIBA INU</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(236, 241, 82, 0.34)" }}><i className="mdi mdi-chart-arc icon-md text-primary" ></i></div>
                    <div className="col-9">
                      <small>Total Bonus User : <br></br></small>
                      <span>{this.state.totalBonusUser} USDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(236, 241, 82, 0.34)" }}>
                      <i className="mdi mdi-arrow-expand icon-md text-primary" ></i></div>
                    <div className="col-9">
                      <small>Total P/L  : <br></br></small>
                      <span>{this.state.transactionPl} USDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-4 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center"
                      style={{ background: "rgba(82, 242, 107, 0.34)" }}>
                      <i className="mdi mdi-cloud-print-outline icon-md text-success"></i></div>

                    <div className="col-9">
                      <small>Total Withdraw USDT : <br></br></small>
                      <span>{this.state.withdrawDeposit} USDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center"
                      style={{ background: "rgba(82, 242, 107, 0.34)" }}>
                      <i className="mdi mdi-cloud-print-outline icon-md text-success"></i></div>

                    <div className="col-9">
                      <small>Total Withdraw BNB  : <br></br></small>
                      <span>{this.state.withdrawDepositBNB} BNB </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-4 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center"
                      style={{ background: "rgba(82, 242, 107, 0.34)" }}>
                      <i className="mdi mdi-cloud-print-outline icon-md text-success"></i></div>

                    <div className="col-9">
                      <small>Total Withdraw SHIBA INU  : <br></br></small>
                      <span>{this.state.withdrawDepositFOXY} SHIBA INU </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>





          </div>


        </> : <>

          <div className="row">
            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(236, 241, 82, 0.34)" }}><i className="mdi mdi-account-multiple-outline icon-md text-primary" ></i></div>

                    <div className="col-9">
                      <small>Total User:  <br></br></small>
                      <span>{this.state.totalUser}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>





            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(236, 241, 82, 0.34)" }}>
                      <i className="mdi mdi-account-multiple-minus icon-md text-primary" ></i></div>

                    <div className="col-9">
                      <small>Total Fake User:  <br></br></small>
                      <span>{this.state.totalUserFake}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center"
                      style={{ background: "rgba(105, 59, 134, 0.20)" }}>
                      <i className="mdi mdi-cart icon-md text-main"></i></div>

                    <div className="col-9">
                      <small>Sales Bot : <br></br></small>
                      <span>{this.state.totalpembelianPackage} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(179, 82, 240, 0.34)" }}><i className="mdi mdi-cash-usd icon-md text-info"></i></div>

                    <div className="col-9">
                      <small>Total Deposit USDT User : <br></br></small>
                      <span>{this.state.totalDepositUserUSDT} USDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(179, 82, 240, 0.34)" }}><i className="mdi mdi-cash-usd icon-md text-info"></i></div>

                    <div className="col-9">
                      <small>Total Deposit BNB User : <br></br></small>
                      <span>{this.state.totalDepositUserBNB} BNB</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center"
                      style={{ background: "rgba(82, 242, 107, 0.34)" }}>
                      <i className="mdi mdi-cloud-print-outline icon-md text-success"></i></div>

                    <div className="col-9">
                      <small>Total Withdraw Deposit User : <br></br></small>
                      <span>{this.state.withdrawDeposit} USDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center"
                      style={{ background: "rgba(82, 242, 107, 0.34)" }}>
                      <i className="mdi mdi-cloud-print-outline icon-md text-success"></i></div>

                    <div className="col-9">
                      <small>Total Withdraw Bonus User : <br></br></small>
                      <span>{this.state.withdrawBonus} USDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(236, 241, 82, 0.34)" }}><i className="mdi mdi-chart-arc icon-md text-primary" ></i></div>
                    <div className="col-9">
                      <small>Total Bonus User : <br></br></small>
                      <span>{this.state.totalBonusUser} USDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-6 pt-2 pb-2">
              <div className="card rounded-lg shadow-lg">
                <div className="card-body">
                  <div className="row">
                    <div className="pr-2 pl-2 align-self-center" style={{ background: "rgba(236, 241, 82, 0.34)" }}>
                      <i className="mdi mdi-arrow-expand icon-md text-primary" ></i></div>
                    <div className="col-9">
                      <small>Total P/L  : <br></br></small>
                      <span>{this.state.transactionPl} USDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>


        </>}



      </div>
    )
  }
}