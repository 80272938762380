import React, { Component } from "react";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.min.css";
import "datatables.net";
import "datatables.net-dt";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";
import JSZip from "jszip";
import pdf from "react-pdfmake";


$.DataTable = require("datatables.net-responsive");

class DataTableStandarDescServerSide extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      th: this.props.th,
      tableName: this.props.tableName,
      baseUrl: process.env.REACT_APP_BASE_URL,
      url: this.props.url,
      data: this.props.data,
      refreshTable: this.props.refreshTable,
      columnDefs: this.props.columnDefs
    };
  }


  removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  componentDidMount() {
    // this.componentFunction();
    this.componentTable();
  }

  componentDidUpdate(prevProps, prevState) {
      console.log(this.props.th)

      if (this.state.th !== this.props.th) {
        this.setState({
          th: this.props.th,
          columnDefs: this.props.columnDefs
        }, () => {
          this.componentTable();
        })
        console.log("update datatable")
      
      }

    if (this.props.refreshTable === true) {
      this.props.onChange(false);
      this.setState({
        data: this.props.data,
      }, () => {
        this.componentTable();
      })
      console.log("dsadasda")
    }
  }

  componentTable = () => {
    window.JSZip = JSZip;
    window.pdf = pdf;

    this.setState({
      table: $(`#${this.state.tableName}`).DataTable({
        order: [[0, 'desc']],
        destroy: true, // I think some clean up is happening here
        processing: true,
        autoWidth: false,
        serverSide: true,
        searching: true,
        ajax: {
          beforeSend: function (request) {
            request.setRequestHeader(
              "Authorization",
              "Bearer " + JSON.parse(localStorage.getItem("admin"))
            );
          },
          url: this.state.baseUrl + this.state.url,
          type: "post",
          data: this.state.data,
        },
        dom: "Bfrtip",
        lengthMenu: [
          [10, 25, 50, -1],
          ["10 entries", "25 entries", "50 entries", "Show All"],
        ],
        language: {
          processing: "Loading...",
          buttons: {
            pageLength: {
              _: "Show %d entries",
              "-1": "Show All entries",
            },
          },
        },
        buttons: [
          "copy",
          "csvHtml5",
          "excelHtml5",
          "pdf",
          "print",
          "pageLength",
        ],
        deferRender: true,
        columns: this.state.th,
        columnDefs: this.state.columnDefs,


        responsive: {
          details: {
            // renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes()
            // renderer: $.fn.dataTable.Responsive.renderer.tableAll()
            renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes()
          }

        },

        //  responsive: true,
        // {
        //       details: false
        //   },

        pagingType: "numbers",
      }),

    });



  };

  render() {
    return (
      <div>
        <div className="table-responsive">
          <table
            className="display font-barlow"
            style={{ width: `100%` }}
            id={this.state.tableName}
          ></table>
        </div>
      </div>
    );
  }
}

export default DataTableStandarDescServerSide;