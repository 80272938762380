import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import { Col, Collapse, Dropdown } from 'react-bootstrap';
import { Collapse } from "react-bootstrap";
import profileModel from "../../services/models/admin/profileModel";

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            role: 'admin',
            usernamee: '',
        }
    }


    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({ [menuState]: false });
        } else if (Object.keys(this.state).length === 0) {
            this.setState({ [menuState]: true });
        } else {
            Object.keys(this.state).forEach((i) => {
                this.setState({ [i]: false });
            });
            this.setState({ [menuState]: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({
                role: 'admin'
            })
            this.onRouteChanged();
        }
    }

    onRouteChanged() {

        this.setState({
            role: 'admin'
        })

        profileModel.me().then(res => {
            console.log(res)

            this.setState({
                role: res.data.position
            })
        })


        document.querySelector("#sidebar").classList.remove("active");
        // Object.keys(this.state).forEach((i) => {
        //     this.setState({ [i]: false });
        // });

        // const dropdownPaths = [
        //     { path: "/transaction", state: "allTranscationMenuOpen" },
        // ];

        // dropdownPaths.forEach((obj) => {
        //     console.log(obj)

        //     if (this.isPathActive(obj.path)) {
        //         this.setState({ [obj.state]: true });
        //     }
        // });
    }


    componentDidMount = () => {

        profileModel.me().then(res => {
            console.log(res)

            this.setState({
                role: res.data.position,
                usernamee: res.data.username
            })
        })
    }


    render() {
        console.log(this.state.role)

        return (
            <nav
                className="sidebar sidebar-offcanvas"
                id="sidebar"
                style={{ height: "100vh", overflow: "hidden scroll" }}
            >
                <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
                    <Link to={"/backoffice-area/dashboard"} className="sidebar-brand brand-logo">
                        {" "}
                        <img src={require("../../assets/images/logo.png")} alt="logo" style={{ width: '80%', maxWidth: "400px", maxHeight: "200px" }} />
                    </Link>
                    <Link to={"/backoffice-area/dashboard"} className="sidebar-brand brand-logo-mini">
                        {" "}
                        <img src={require("../../assets/images/icon.png")} alt="logo" />
                    </Link>
                </div>
                <ul className="nav">
                    <li className="nav-item nav-category">
                        <span className="nav-link">Main Menu</span>
                    </li>
                    <li
                        className={
                            this.isPathActive("/backoffice-area/dashboard")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/dashboard">
                            <span className="menu-icon">
                                <i className="mdi mdi-speedometer"></i>
                            </span>
                            <span className="menu-title">Dashboard</span>
                        </Link>
                    </li>

                    <li className="nav-item nav-category">
                        <span className="nav-link"> User </span>
                    </li>


                    <li
                        className={
                            this.isPathActive("/backoffice-area/user")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/user">
                            <span className="menu-icon">
                                <i className="mdi mdi-account-multiple"></i>
                            </span>
                            <span className="menu-title">Manage User</span>
                        </Link>
                    </li>


                    <li
                        className={
                            this.isPathActive("/backoffice-area/tree/referral")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/tree/referral">
                            <span className="menu-icon">
                                <i className="mdi mdi-file-tree"></i>
                            </span>
                            <span className="menu-title">Referral Tree</span>
                        </Link>
                    </li>


                    {/* <li
                        className={
                            this.isPathActive("/backoffice-area/swap/history")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/swap/history">
                            <span className="menu-icon">
                                <i className="mdi mdi-swap-horizontal"></i>
                            </span>
                            <span className="menu-title">Swap History</span>
                        </Link>
                    </li> */}


                    {/* <li
                        className={
                            this.isPathActive("/backoffice-area/send/history")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/send/history">
                            <span className="menu-icon">
                                <i className="mdi mdi-subdirectory-arrow-right"></i>
                            </span>
                            <span className="menu-title">History Send Token </span>
                        </Link>
                    </li> */}


                    {/* <li className="nav-item nav-category">
                        <span className="nav-link">Exchanger  </span>
                    </li>


                    <li
                        className={
                            this.isPathActive("/backoffice-area/manage/exchanger")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/manage/exchanger">
                            <span className="menu-icon">
                                <i className=" mdi mdi-package-variant"></i>
                            </span>
                            <span className="menu-title">Exchanger </span>
                        </Link>
                    </li> */}



                    {/* <li className="nav-item nav-category">
                        <span className="nav-link">Bot Package </span>
                    </li>


                    <li
                        className={
                            this.isPathActive("/backoffice-area/bot/package")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/bot/package">
                            <span className="menu-icon">
                                <i className=" mdi mdi-package-variant"></i>
                            </span>
                            <span className="menu-title">Bot Package</span>
                        </Link>
                    </li>

                    <li
                        className={
                            this.isPathActive("/backoffice-area/buy/package/history")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/buy/package/history">
                            <span className="menu-icon">
                                <i className="mdi mdi-cart-outline"></i>
                            </span>
                            <span className="menu-title">Buy Bot History</span>
                        </Link>
                    </li>


                    <li className="nav-item nav-category">
                        <span className="nav-link">Voucher</span>
                    </li>


                    <li
                        className={
                            this.isPathActive("/backoffice-area/voucher")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/voucher">
                            <span className="menu-icon">
                                <i className="mdi mdi-ticket-confirmation"></i>
                            </span>
                            <span className="menu-title">Voucher</span>
                        </Link>
                    </li>

                    <li className="nav-item nav-category">
                        <span className="nav-link">Bot Setup </span>
                    </li> */}


                    {/* <li
                        className={
                            this.isPathActive("/backoffice-area/manage-token")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/manage-token">
                            <span className="menu-icon">
                                <i className="mdi mdi-coin"></i>
                            </span>
                            <span className="menu-title">Manage Token</span>
                        </Link>
                    </li> */}





                    {/* <li
                        className={
                            this.isPathActive("/backoffice-area/open-trade")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/open-trade">
                            <span className="menu-icon">
                                <i className="mdi mdi-checkbox-multiple-blank-circle-outline"></i>
                            </span>
                            <span className="menu-title">Open Trade</span>
                        </Link>
                    </li> */}



{/* 
                    <li
                        className={
                            this.isPathActive("/backoffice-area/setup/group")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/setup/group">
                            <span className="menu-icon">
                                <i className="mdi mdi-group"></i>
                            </span>
                            <span className="menu-title">Create & Setup Group</span>
                        </Link>
                    </li> */}
{/* 
                    <li
                        className={
                            this.isPathActive("/backoffice-area/trade/group")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/trade/group">
                            <span className="menu-icon">
                                <i className="mdi mdi-transfer"></i>
                            </span>
                            <span className="menu-title">Open Trade Group</span>
                        </Link>
                    </li> */}



                    {/* <li
                        className={
                            this.isPathActive("/backoffice-area/live/chart")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/live/chart">
                            <span className="menu-icon">
                                <i className="mdi mdi-chart-line"></i>
                            </span>
                            <span className="menu-title">Live Chart</span>
                        </Link>
                    </li> */}


                    {/* <li
                        className={
                            this.isPathActive("/backoffice-area/bot/rules")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/bot/rules">
                            <span className="menu-icon">
                                <i className="mdi mdi-shape-circle-plus"></i>
                            </span>
                            <span className="menu-title">Bot Rules</span>
                        </Link>
                    </li>

                    <li
                        className={
                            this.isPathActive("/backoffice-area/bot/data")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/bot/data">
                            <span className="menu-icon">
                                <i className="mdi mdi-shape-circle-plus"></i>
                            </span>
                            <span className="menu-title">Data Active Bot</span>
                        </Link>
                    </li> */}




                    <li className="nav-item nav-category">
                        <span className="nav-link">Transaction </span>
                    </li>


                  
                        <li
                            className={
                                this.isPathActive("/backoffice-area/deposit")
                                    ? "nav-item menu-items active"
                                    : "nav-item menu-items"
                            }
                        >
                            <Link className="nav-link" to="/backoffice-area/deposit">
                                <span className="menu-icon">
                                    <i className="mdi mdi-cash-multiple"></i>
                                </span>
                                <span className="menu-title">Deposit</span>
                            </Link>
                        </li>


                        <li
                            className={
                                this.isPathActive("/backoffice-area/withdraw")
                                    ? "nav-item menu-items active"
                                    : "nav-item menu-items"
                            }
                        >
                            <Link className="nav-link" to="/backoffice-area/withdraw">
                                <span className="menu-icon">
                                    <i className="mdi mdi-cards-playing-outline"></i>
                                </span>
                                <span className="menu-title">Withdraw</span>
                            </Link>
                        </li>







{/* 
                    <li
                        className={
                            this.isPathActive("/backoffice-area/bonus")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/bonus">
                            <span className="menu-icon">
                                <i className="mdi mdi-chart-histogram"></i>
                            </span>
                            <span className="menu-title">Bonuses</span>
                        </Link>
                    </li> */}


                    <li
                        className={
                            this.isPathActive("/backoffice-area/bet/bonus")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/bet/bonus">
                            <span className="menu-icon">
                                <i className="mdi mdi-chart-histogram"></i>
                            </span>
                            <span className="menu-title">Bonuses Direct BET</span>
                        </Link>
                    </li>

                    <li
                        className={
                            this.isPathActive("/backoffice-area/bet/history")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/bet/history">
                            <span className="menu-icon">
                                <i className="mdi mdi-chart-histogram"></i>
                            </span>
                            <span className="menu-title">BET History</span>
                        </Link>
                    </li>


                    <li
                        className={
                            this.isPathActive("/backoffice-area/rank")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                        }
                    >
                        <Link className="nav-link" to="/backoffice-area/rank">
                            <span className="menu-icon">
                                <i className="mdi mdi-stairs"></i>
                            </span>
                            <span className="menu-title">Rank</span>
                        </Link>
                    </li>


                    {this.state.usernamee !== 'FoxY4DM' ? <>
                        <li
                            className={
                                this.isPathActive("/backoffice-area/setup/variable")
                                    ? "nav-item menu-items active"
                                    : "nav-item menu-items"
                            }
                        >
                            <Link className="nav-link" to="/backoffice-area/setup/variable">
                                <span className="menu-icon">
                                    <i className="mdi mdi-settings"></i>
                                </span>
                                <span className="menu-title">Setup</span>
                            </Link>
                        </li>
                     

                    

                    </> : <></>}
                    <li
                            className={
                                this.isPathActive("/backoffice-area/setup/bet")
                                    ? "nav-item menu-items active"
                                    : "nav-item menu-items"
                            }
                        >
                            <Link className="nav-link" to="/backoffice-area/setup/bet">
                                <span className="menu-icon">
                                    <i className="mdi mdi-settings"></i>
                                </span>
                                <span className="menu-title">Setup BET</span>
                            </Link>
                        </li>

                        <li
                            className={
                                this.isPathActive("/backoffice-area/monitor")
                                    ? "nav-item menu-items active"
                                    : "nav-item menu-items"
                            }
                        >
                            <Link className="nav-link" to="/backoffice-area/monitor">
                                <span className="menu-icon">
                                    <i className="mdi mdi-monitor"></i>
                                </span>
                                <span className="menu-title">Monitoring VIP</span>
                            </Link>
                        </li>



                        <li
                            className={
                                this.isPathActive("/backoffice-area/non/vip")
                                    ? "nav-item menu-items active"
                                    : "nav-item menu-items"
                            }
                        >
                            <Link className="nav-link" to="/backoffice-area/non/vip">
                                <span className="menu-icon">
                                    <i className="mdi mdi-monitor"></i>
                                </span>
                                <span className="menu-title">Monitoring NON VIP</span>
                            </Link>
                        </li>

                </ul>
            </nav>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }

    componentDidMount() {
        this.onRouteChanged();
        // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        const body = document.querySelector("body");
        document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
            el.addEventListener("mouseover", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.add("hover-open");
                }
            });
            el.addEventListener("mouseout", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.remove("hover-open");
                }
            });
        });
    }
}

export default withRouter(Sidebar);
