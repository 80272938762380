import authHeaderAdmin from "../../auth-header-admin";


class generalModel {

    variableGeneral() {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/variable')
    }

    variableGeneralUpdate(wallet, currencyBNB, minWithdraw, feeWithdraw, walletfee, privatekey, feeSendWithdraw, royalthy,
        addressFeePassword, amountFeePassword, addressFeeProfile, amountFeeProfile, addressFeeStart, amountFeeStart, addressFeeMoveToWallet, amountFeeMoveToWallet, addressFeeSwapToken, amountFeeSwapToken, addressFeeSendToken, amountFeeSendToken, minWithdrawBNB,
        feeWithdrawBNB,
        minWithdrawFOXY,
        feeWithdrawFOXY,
        directbonusfoxy,
        directbonus,
        dailycheckin
    ) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/variable/update', {
            wallet,currencyBNB, minWithdraw, feeWithdraw, walletfee, privatekey, feeSendWithdraw, royalthy, addressFeePassword, amountFeePassword, addressFeeProfile, amountFeeProfile, addressFeeStart, amountFeeStart,
            addressFeeMoveToWallet,
            amountFeeMoveToWallet,
            addressFeeSwapToken,
            amountFeeSwapToken,
            addressFeeSendToken,
            amountFeeSendToken,
            minWithdrawBNB,
            feeWithdrawBNB,
            minWithdrawFOXY,
            feeWithdrawFOXY,
            directbonusfoxy,
            directbonus,
            daily_checkin: dailycheckin
        })
    }

    addLevel(level, percentase) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/add/level', {
            level, percentase
        })
    }

    addLevelinggasfee(level, percentase) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/add/level/gas/fee', {
            level, percentase
        })
    }



    editLevelGasFee(id, level, percentase) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/edit/level/gas/fee', {
            id, level, percentase
        })
    }




    deleteLevelgasfee(id) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/delete/level/gas/fee', {
            id
        })
    }

    addLevelWithdraw(level, percentase) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/add/withdraw/level', {
            level, percentase
        })
    }

    editLevel(id, level, percentase) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/edit/level', {
            id, level, percentase
        })
    }

    editLevelWithdraw(id, level, percentase) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/edit/level/withdraw', {
            id, level, percentase
        })
    }

    deleteLevel(id) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/delete/level', {
            id
        })
    }
    deleteLevelWithdraw(id) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/delete/level/withdraw', {
            id
        })
    }


    updatePassword(currentPassword, newPassword, confimPassword) {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/update/password', {
            currentPassword,
            newPassword,
            confimPassword
        })
    }



    variableTrade(){
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/variable/trade')
    }

    variableTradeUpdate(vipWin,
        vipLose,
        dailyWin,
        dailyLose,
        maxProfitUsdt,
        maxProfitBnb,
        maxProfitFoxy,
        kondisi1,
        kondisi2,
        kondisi3,
        kondisi4,
        kondisi5,
        kondisi6,
        kondisi7,
        kondisi8,
        kondisi9,
        kondisi10,
        // win_limit_usdt,
        // win_limit_bnb,
        // win_limit_shiba_inu,

    
    ){
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/variable/trade/update', {
        vipWin,
        vipLose,
        dailyWin,
        dailyLose,
        maxProfitUsdt,
        maxProfitBnb,
        maxProfitFoxy,
        kondisi1,
        kondisi2,
        kondisi3,
        kondisi4,
        kondisi5,
        kondisi6,
        kondisi7,
        kondisi8,
        kondisi9,
        kondisi10,
        // win_limit_usdt,
        // win_limit_bnb,
        // win_limit_shiba_inu,
        })
    }



    editVariable(id, maxToday, maxProfit, type){
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/variable/update/trade', {
            id,maxToday,maxProfit,type
        })
    }


    editVariableNon(id, maxToday, maxProfit, dailyWin,type){
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/variable/non/update', {
            id,maxToday,maxProfit,dailyWin,type
        })
    }



    startStopVariable(id, type){
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/general/variable/startstop',{
            id, type
        })
    }

}

export default new generalModel();
